<template>
    <div class="box form-box has-background-white">
        <div class="enso-form">
            <enso-form ref="form" @ready="init">
                <template v-slot:image="props">
                    <div class="columns custom-section-wrapper is-multiline">
                        <div class="column is-12 section-description">
                            <div class="field">
                                <div class="label">
                                    {{ i18n(props.field.label) }}
                                </div>
                            </div>
                        </div>
                        <div class="column is-12">
                            <figure class="avatar image is-64x64 padding-top-2">
                                <img :src="route('pins.show', image)">
                            </figure>
                        </div>
                        <div class="column is-12">
                            <div class="flex align-items-center padding-bottom-2">
                                <uploader :url="route('pins.update', $route.params.pin)"
                                          @upload-successful="addUploadedFile"/>
                            </div>
                        </div>
                    </div>
                </template>
            </enso-form>
        </div>
    </div>
</template>

<script>
import { EnsoForm } from '@enso-ui/forms/bulma';
import { Uploader } from '@enso-ui/uploader/bulma';

export default {
    name: 'Edit',
    components: { EnsoForm, Uploader },
    inject: ['i18n', 'route'],
    data: () => ({
        image: null,
        ready: false,
    }),
    computed: {
        form() {
            return this.ready
                ? this.$refs.form.$refs.form
                : {};
        },
    },
    methods: {
        addUploadedFile() {
            this.$router.push({ name: 'pins.index' });
        },
        init() {
            this.ready = true;
            this.image = this.form.field('image').value;
        },
    },
};
</script>
