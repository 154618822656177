var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box form-box has-background-white" }, [
    _c(
      "div",
      { staticClass: "enso-form" },
      [
        _c("enso-form", {
          ref: "form",
          on: { ready: _vm.init },
          scopedSlots: _vm._u([
            {
              key: "image",
              fn: function(props) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "columns custom-section-wrapper is-multiline"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "column is-12 section-description" },
                        [
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                " " + _vm._s(_vm.i18n(props.field.label)) + " "
                              )
                            ])
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "column is-12" }, [
                        _c(
                          "figure",
                          {
                            staticClass: "avatar image is-64x64 padding-top-2"
                          },
                          [
                            _c("img", {
                              attrs: { src: _vm.route("pins.show", _vm.image) }
                            })
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "column is-12" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex align-items-center padding-bottom-2"
                          },
                          [
                            _c("uploader", {
                              attrs: {
                                url: _vm.route(
                                  "pins.update",
                                  _vm.$route.params.pin
                                )
                              },
                              on: { "upload-successful": _vm.addUploadedFile }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }